/**
 * `Connector` is a class that simplifies the usage of PostMessage API.
 * 
 * - sets up communication channels between current window and target window
 *   - current window refers to the global scope window
 *   - target window refers to the iframe, or to the parent window
 * - implements a messaging protocol between the two windows
 *   - EventMessage     -- for one-off event
 *   - CallMessage      -- to make Remote Procedure Calls (RPC)
 *   - ReplyMessage     -- response to a specific CallMessage
 */
import { Message, EventMessage, CallMessage, ReplyMessage } from "./Message";
import { Signal } from "./Signal";
import { uuidv4 } from "./Uuid";


type Pass = (value: any) => void;
type Fail = (error: any) => void;
type Outcome = { pass: Pass, fail: Fail };

export type ConnectorFunctionHandler = (this: Connector, ...args: any[]) => void;
export type ConnectorEventHandler = (this: Connector, event: any) => void;

export interface ConnectorOptions {
    name: string;
    allowedOrigins: string[];
    targetWindow: Window;
    remote: string;
    functions: Record<string, ConnectorFunctionHandler>;
    events: Record<string, ConnectorEventHandler>;
}


export class Connector {
    public options: ConnectorOptions;
    private awaiting: Map<string, Outcome>;
    public onEvent: Signal<any>;
    public onMessage: Signal<Message>;

    public constructor(options: ConnectorOptions) {
        this.options = options;
        this.awaiting = new Map();
        this.onEvent = new Signal();
        this.onMessage = new Signal();
        this.handleMessage = this.handleMessage.bind(this);
        this.init();
    }

    public init() {
        const options = this.options;

        for (const [k, v] of Object.entries(options.functions)) {
            options.functions[k] = v.bind(this);
        }

        for (const [k, v] of Object.entries(options.events)) {
            options.events[k] = v.bind(this);
        }

        window.addEventListener("message", this.handleMessage, false);
    }

    private generateId(): string {
        let id = uuidv4();
        while (this.awaiting.has(id)) {
            id = uuidv4();
        }
        return id;
    }

    /** Generic callback handler for all message types */
    private async handleMessage(event: MessageEvent) {
        const isMatchingSource = event.source === this.options.targetWindow;
        const isAllowedOrigins = this.options.allowedOrigins.includes(event.origin);
        const isCorrectRecipient = isMatchingSource && isAllowedOrigins;

        // ignore all messages not intended for us
        if (!isCorrectRecipient) return;
        
        const message: Message = JSON.parse(event.data);
        this.onMessage.dispatch(message);

        // delegate to specific message handlers
        switch (message.type) {
            case "call": return this.handleCall(message);
            case "reply": return this.handleReply(message);
            case "event": return this.handleEvent(message);
            default:
                throw new Error(`Unexpected message type ${message!.type}`);
        }
    }

    private async handleCall(message: CallMessage) {
        const { id, fn, args } = message;
        try {
            const result = await this.dispatch(fn, ...args);
            this.reply(id, "success", result);
        } catch (error) {
            this.reply(id, "error", error);
        }
    }

    private handleReply(message: ReplyMessage) {
        const { status, id, value } = message;
        const outcome = this.awaiting.get(id);
        if (!outcome) {
            console.warn(`No such outcome id`);
            return;
        }
        
        this.awaiting.delete(id);
        
        if (status === "success") {
            outcome.pass(value);
        } else {
            outcome.fail(value);
        }
    }

    private handleEvent(message: EventMessage) {
        const { name, event } = message;
        const handler = this.options.events[name] as any;
        if (handler) {
            handler(event);
        }
        this.onEvent.dispatch({ name, event });
    }

    /** Calls a function defined in this connector's options (triggered by targetWindow) */
    public dispatch(fn: string, ...args: any[]) {
        const f = this.options.functions[fn];
        if (typeof f !== "function") {
            throw new Error(`undefined function ${fn}`);
        }
        return f.apply(this, args);
    }

    /** Sends a raw Message to targetWindow */
    public send(message: Message) {
        if (!origin) {
            throw new Error("not initialized");
        }
        const { targetWindow } = this.options;
        targetWindow.postMessage(JSON.stringify(message), this.options.remote);
    }

    /** Sends an EventMessage to targetWindow */
    public trigger(name: string, event?: any) {
        const message: EventMessage = { type: "event", name, event };
        this.send(message);
    }

    /** Sends a CallMessage to targetWindow */
    public call(fn: string, ...args: any[]) {
        return new Promise((pass, fail) => {
            const id = this.generateId();
            const message: CallMessage = { type: "call", id, fn, args };
            this.awaiting.set(id, { pass, fail });
            this.send(message);
        });
    }

    /** Send a ReplyMessage to targetWindow */
    public reply(id: string, status: "success" | "error", value: any) {
        const message: ReplyMessage = { type: "reply", id, status, value };
        this.send(message);
    }
}
