import { IFrameClient } from "./IFrameClient";

function main() {
    console.log("client!");
    (window as any).CaptureIFrame = IFrameClient;
}

main();

export default IFrameClient;
