import { IFrameClientOptions, IFrameHostOptions } from "~/types/IFrame";
import { Connector } from "~/util/Connector";
import { debounce } from "~/util/Function";
import { Message } from "~/util/Message";
import { Signal } from "~/util/Signal";
import { ENV } from "~/config/Env";

// this runs on customer's website.

const RESIZE_DEBOUNCE_MS = 250;


export class IFrameClient {
    public options: IFrameClientOptions;
    public connector!: Connector;
    public iframe!: HTMLIFrameElement;
    public autoSize: boolean;

    public onLoad = new Signal<Event>();
    public onMessage = new Signal<Message>();
    public onReady = new Signal<void>();
    public onEvent = new Signal<any>();
    public onMouseOver = new Signal<any>();
    public onMouseOut = new Signal<any>();

    public constructor(options: IFrameClientOptions) {
        console.log("IFrameClient initializing...");
        this.handleResize = debounce(RESIZE_DEBOUNCE_MS, this.handleResize.bind(this));
        this.options = options;
        this.autoSize = !!this.options.autoSize;
        this.initAll();
    }

    public static init(options: IFrameClientOptions) {
        return new IFrameClient(options);
    }

    private initAll() {
        this.initIFrame(this.options.element);
        this.initConnector();
        this.initEvents();
    }

    private initIFrame(el: HTMLElement) {
        if (!el) {
            throw new Error("element not found");
        }

        const params = new URLSearchParams();
        params.append("token", this.options.token || "");
        const URL = `${ENV.iframe.url}?${params}`;

        const iframe = document.createElement("iframe");
        this.iframe = iframe;
        iframe.setAttribute("src", URL);
        iframe.className = "capture-self-service__iframe";
        el.appendChild(iframe);

        iframe.addEventListener("load", event => {
            this.onLoad.dispatch(event);
        });

        window.addEventListener("resize", this.handleResize);
    }

    private initConnector() {
        const self = this;
        const eventMap: Record<string, Signal<any>> = {
            mouseover: this.onMouseOver,
            mouseout: this.onMouseOut,
        };

        this.connector = new Connector({
            name: "client",
            allowedOrigins: [ENV.iframe.origin],
            targetWindow: this.iframe.contentWindow!,
            remote: ENV.iframe.origin,
            functions: {},
            events: {
                onLoad() {
                    console.log("client onLoad!");
                    const options: IFrameHostOptions = {
                        customizations: self.options.customizations ?? {},
                    };
                    this.trigger("init", options);
                },

                onReady() {
                    console.log("client onReady!");
                    self.onReady.dispatch();
                    self.handleResize();
                },

                onEvent(event) {
                    self.onEvent.dispatch(event);
                    if (event.type in eventMap) {
                        eventMap[event.type].dispatch(event);
                    }
                },
            },
        });

        this.connector.onMessage.add(message => this.onMessage.dispatch(message));
    }

    private initEvents() {
        const events = (this.options.events || {}) as Record<string, (...args: any[]) => void>;
        for (const [eventName, handler] of Object.entries(events)) {
            const signal = (this as any)[eventName] as Signal | undefined;
            if (signal instanceof Signal) {
                signal.add(handler.bind(this));
            }
        }
    }

    private async handleResize() {
        if (this.autoSize) {
            const scrollHeight = await this.getHeight();
            this.iframe.style.height = `${scrollHeight}px`;
        }
    }

    public setTheme(theme: any) {
        this.connector.trigger("setTheme", theme);
    }

    public setStyles(styles: any) {
        this.connector.trigger("setStyles", styles);
    }

    public resetStyles() {
        this.connector.trigger("resetStyles");
    }

    public getHeight() {
        return this.connector.call("getHeight");
    }
}
