
/** Generates a random 4 digit hex. */
function randHex4(): string {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

/**
 * Generates a uuidv4.
 * This is not cryptographically secure, but good enough
 * for dynamic html element IDs.
 */
export function uuidv4(): string {
    return "xx-x-x-x-xxx".replace(/x/g, randHex4);
}
