/**
 * Utils for functions
 */

/**
 * Take a function and turn it into a function that when invoked repeatedly,
 * will call the original function after intervalInMs since the last invocation.
 *
 * Used when you want to call a function after event has stopped recurring.
 * i.e. scroll, resize, mousemove, keypress
 *
 * @example
 * window.addEventListener("resize", debounce(250, () => console.log("foo")));
 *
 * @example
 * let clicked: Signal<Button> = ...;
 * clicked.add(debounce(() => console.log("clicked")));
 */
export function debounce<F extends (...args: any[]) => void>(intervalInMs: number, fn: F): F {
    let timer: any;
    return function (this: any, ...args: any[]): void {
        if (timer !== undefined) clearTimeout(timer);
        timer = setTimeout(() => fn(...args), intervalInMs);
    } as F;
}

/**
 * Take a function and turn it into a function that when invoked repeatedly,
 * will call the original function once per intervalInMs.
 *
 * Used for events that happen a lot and you want to limit it.
 * i.e. mousemove, keypress
 */
export function throttle<F extends (...args: any[]) => void>(intervalInMs: number, fn: F): F {
    let prevTime = 0;
    return function(this: any, ...args: any[]): void {
        const currTime = Date.now();
        const deltaTime = currTime - prevTime;
        if (deltaTime >= intervalInMs) {
            prevTime = currTime;
            fn(...args);
        }
    } as F;
}


const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
const ARGUMENT_NAMES = /([^\s,]+)/g;
const LAMBDA_1 = /^\s*([^\s,]+)\s*=>/;

/**
 * Retrieve function parameter names.
 * IMPORTANT: this doesn't work when mangle is enabled in terser/uglify.
 */
export function getFunctionArgNames(fn: (...args: any[]) => any): string[] {
    const fnStr = fn.toString().replace(STRIP_COMMENTS, '');

    const lambda1 = fnStr.match(LAMBDA_1);
    if (lambda1) {
        return [lambda1[1]];
    }

    const parenOpen = fnStr.indexOf("(");
    const parenClose = fnStr.indexOf(")");
    const argStr = fnStr.substring(parenOpen + 1, parenClose);
    return argStr.split(",").map(s => s.trim());
}
