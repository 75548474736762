
function joinPath(...parts: (string | undefined)[]): string {
    return parts.filter(p => !!p).join("/");
}

const IFRAME_HOST = process.env.IFRAME_CAPTURE_HOST;
const IFRAME_VERSION = process.env.IFRAME_VERSION;
const IFRAME_PATH = process.env.IFRAME_PATH;

const IFRAME_ORIGIN = IFRAME_HOST === "/" ? window.location.origin : IFRAME_HOST;
const IFRAME_URL = joinPath(IFRAME_ORIGIN, IFRAME_VERSION, IFRAME_PATH);

export const ENV = {
    nodeEnv: process.env.NODE_ENV,
    marketing: process.env.MARKETING,
    apiUrl: process.env.API_URL,
    timezone: process.env.TIMEZONE,
    recaptchaKey: process.env.RECAPTCHA_SITE_KEY,
    iframe: {
        host: IFRAME_HOST,
        version: IFRAME_VERSION,
        path: IFRAME_PATH,
        origin: IFRAME_ORIGIN,
        url: IFRAME_URL,
    },
    debug: {
        mockLatency: +process.env.MOCK_LATENCY,
    },
} as const;
