/**
 * An alternative to EventListener/Dispatcher pattern.
 * A `Signal` is a dispatcher specific to one event.
 * 
 * Some benefits over EventListener/Dispatcher:
 * - discoverability of events via code completion (intellisense)
 * - slots are queryable (you can clear all listeners of a particular event)
 * - some implementations of Signals can be "monadic"
 *   - e.g. `signal.map(x => x.foo).filter(foo => foo.length > 3).add(foo => console.log(foo))`
 * 
 * @example
 * // using EventListener
 * thing.addEventListener("ready", handleReady);
 * 
 * // using signals
 * thing.onReady.add(handleReady);
 */

export type Listener<T> = (value: T) => void;
export type Slots<T> = Listener<T>[];

export class Signal<T = any> {
    public slots: Slots<T>;

    public constructor() {
        this.slots = [];
    }

    public add(listener: Listener<T>): void {
        this.slots.push(listener);
    }

    public once(listener: Listener<T>): void {
        const wrap = (value: T): void => {
            listener(value);
            this.remove(wrap);
        };
        this.add(wrap);
    }

    public next(): Promise<T> {
        return new Promise(pass => this.once(value => pass(value)));
    }

    public remove(listener: Listener<T>): void {
        const i = this.slots.indexOf(listener);
        this.slots.splice(i, 1);
    }

    public clear(): void {
        this.slots.splice(0, this.slots.length);
    }

    public dispatch(value: T): void {
        for (const listener of this.slots) {
            listener(value);
        }
    }

    // ----------

    public static flatten<T>(signal: Signal<Signal<T>>): Signal<T> {
        const s = new Signal<T>();
        signal.add(inner => inner.add(t => s.dispatch(t)));
        return s;
    }

    public chain<U>(fn: (value: T) => Signal<U>): Signal<U> {
        return Signal.flatten(this.map(fn));
    }

    public map<U>(fn: (value: T) => U): Signal<U> {
        const signal = new Signal<U>();
        this.add(x => signal.dispatch(fn(x)));
        return signal;
    }

    public filter(fn: (value: T) => boolean): Signal<T> {
        const signal = new Signal<T>();
        this.add(x => { if (fn(x)) signal.dispatch(x); });
        return signal;
    }

    public concat(that: Signal<T>): Signal<T> {
        const signal = new Signal<T>();
        this.add(x => signal.dispatch(x));
        that.add(x => signal.dispatch(x));
        return signal;
    }
}
